import { useImageCropContext } from '@/app/providers/ImageCropProvider'
import EasyCropper from 'react-easy-crop'
import { ActionIcon } from '@mantine/core'
import { Undo2 } from 'lucide-react'
import { Button } from '@/shared/ui/Button/Button'

export const CropperNow = ({ handleDone, onRevert }) => {
  const { image, zoom, setZoom, crop, setCrop, onCropComplete } = useImageCropContext()

  return (
    <div className='flex items-end relative w-[auto] h-[280px]'>
      <div className=' absolute top-0 left-0 right-0 bottom-0 h-[220px] max-w-[260px]'>
        <EasyCropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid
          cropShape='rect'
          objectFit='horizontal-cover'
        />
      </div>
      <div className='absolute left-[230px] top-3'>
        <ActionIcon
          variant='light'
          size='sm'
          className='bg-red hover:bg-red/80 text-white'
          onClick={onRevert}
        >
          <Undo2 size={14} />
        </ActionIcon>
      </div>
      <Button variant='primary' className='w-full' onClick={handleDone}>
        Done & Save
      </Button>
    </div>
  )
}

export default CropperNow
