import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button } from '@/shared/ui/Button/Button'
import { TextAreaField, TextField } from '@/shared/ui/FormFields'
import { MessageVariablesType } from '@/widgets/Settings/LibraryView/MessageTemplatesView/lib/constant'
import { Badge, Loader, Tooltip } from '@mantine/core'
import { handleCopy } from '@/shared/helpers/helpers'
import { useEditeMessageTemplateForm } from '../lib/use-edit-message-template-form'
import { editMessageTemplateFormScheme } from '../lib/consts'

type EditMessageTemplateFormProps = {
  selectTemplateMessageId: number
  onCloseModal: () => void
}

export const EditMessageTemplateForm: FC<EditMessageTemplateFormProps> = props => {
  const { selectTemplateMessageId, onCloseModal } = props
  const { t } = useTranslation(['common'])
  const { initialValues, isLoadingTemplate, isLoadingEditMessageTemplate, submitForm } =
    useEditeMessageTemplateForm({
      onCloseModal,
      seletectedTemplateId: selectTemplateMessageId,
    })

  const variables = [MessageVariablesType.RecoveryCode]

  if (isLoadingTemplate) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={editMessageTemplateFormScheme(t)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div>
            <TextField
              name='name'
              label={t('common:library_items.message_template.name')}
              type='text'
              className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
              required
            />
            <TextField
              name='comment'
              label={t('common:library_items.message_template.comment')}
              type='text'
              className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
            />
            <TextAreaField
              name='content'
              label='HTML'
              placeholder='<html dir="ltr" xmlns:o="urn:schemas-microsoft-com:office:office"></>'
              className='flex flex-col min-w-[calc(50%-0.5rem)] mb-4'
              required
            />
          </div>

          <div className='flex flex-col'>
            <p className='text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2'>
              {t('common:library_items.message_template.variables')}:
            </p>
            <div className='flex flex-wrap gap-2 mb-4'>
              {variables.map((variable, index: number) => {
                const formVariable = '${' + variable + '}'
                return (
                  <Tooltip
                    label={t('common:copy')}
                    style={{ fontSize: 12 }}
                    transitionProps={{ transition: 'scale', duration: 300 }}
                  >
                    <Badge
                      key={index}
                      onClick={() => handleCopy(formVariable)}
                      style={{ textTransform: 'none', cursor: 'pointer' }}
                      size='sm'
                    >
                      {formVariable}
                    </Badge>
                  </Tooltip>
                )
              })}
            </div>
          </div>
          <div className='flex flex-row w-full'>
            <Button type='submit' size='md' color='base' disabled={isLoadingEditMessageTemplate}>
              {t('common:library_items.message_template.buttons.edit_template')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
