import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from 'mantine-react-table'
import { usePublicOffers } from '@/entities/Offer/api/public-offer-api'
import { formatDateForTable } from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { OfferListItem } from '@/shared/api/topli/TopliApi'

export const usePublicOfferTable = () => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  })
  const { language } = useLanguageStore()
  const { t } = useTranslation(['common'])

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: publicOffers,
    isLoading: isLoadingPublicOffers,
    isError: isErrorPublicOffers,
    isFetching: isFetchingPublicOffers,
  } = usePublicOffers({
    sort: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [])

  const fetchedPublicOffers = publicOffers?.data ?? []

  const columns = useMemo<MRT_ColumnDef<OfferListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: `${t('common:name')}`,
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>{`offer_v${row.original.id}`}</div>
        ),
      },
      {
        accessorKey: 'created_at',
        header: `${t('common:created_at')}`,
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {formatDateForTable(row.original.created_at, language)}
          </div>
        ),
      },
    ],
    [language],
  )
  return {
    pagination,
    columns,
    publicOffers,
    fetchedPublicOffers,
    isErrorPublicOffers,
    isFetchingPublicOffers,
    isLoadingPublicOffers,
    sorting,
    setSorting,
    setPagination,
  }
}
