import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusCircleIcon } from 'lucide-react'
import { Title } from '@mantine/core'
import { DeleteMessageTemplateAlert } from '@/features/MessageTemplates/DeleteMessageTemplate'
import { Button } from '@/shared/ui/Button/Button'
import { MessageTemplatesTable } from './MessageTemplatesTable/MessageTemplatesTable'
import { CreateMessageTemplateModal } from './Modals/CreateMessageTemplateModal'
import { EditMessageTemplateModal } from './Modals/EditMessageTemplateModal'

export const MessageTemplatesView = () => {
  const [currentTable, setCurrentTable] = useState<any | null>(null)
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false)
  const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const { t } = useTranslation(['common'])

  const handleOpenDeleteModal = (selectedItem: any) => {
    setCurrentTable(selectedItem)
    setOpenDeleteModal(true)
  }

  const handleOpenEditModal = (selectedItem: any) => {
    setCurrentTable(selectedItem)
    setOpenEditModal(true)
  }

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const handleCloseModal = () => {
    setOpenCreateModal(false)
    setOpenEditModal(false)
    setOpenDeleteModal(false)
  }

  return (
    <div className='flex flex-col mb-5 md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <Title className='pb-5' order={4}>
        {t('common:library_items.message_template.pl')}
      </Title>
      <MessageTemplatesTable
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={handleOpenCreateModal}
            leftIcon={<PlusCircleIcon size={16} />}
          >
            {t('common:library_items.message_template.buttons.new_template')}
          </Button>
        }
      />
      <CreateMessageTemplateModal
        onCloseModal={handleCloseModal}
        dialogProps={{ open: isOpenCreateModal, onOpenChange: setOpenCreateModal }}
      />
      {currentTable && (
        <EditMessageTemplateModal
          onCloseModal={handleCloseModal}
          selectedTemplateId={currentTable.id}
          dialogProps={{ open: isOpenEditModal, onOpenChange: setOpenEditModal }}
        />
      )}
      {currentTable !== null && (
        <DeleteMessageTemplateAlert
          open={isOpenDeleteModal}
          onOpenChange={setOpenDeleteModal}
          selectMessageTemplateId={currentTable.id}
          onCloseModal={handleCloseModal}
        />
      )}
    </div>
  )
}
