import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { ServiceFeeForm } from '@/features/ServiceFee'

export const ServiceFeeView = () => {
  const { t } = useTranslation(['common'])
  return (
    <div className='flex flex-col mb-5 w-full mx-0 bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <Title order={4}>{`${t('common:library_items.fee.service_fee')}, %`}</Title>
      <div className='mt-3'>
        <ServiceFeeForm />
      </div>
    </div>
  )
}
