import { FC } from 'react'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { CreateMessageTemplateForm } from '@/features/MessageTemplates/CreateMessageTemplateForm'

type CreateMessageTemplateModalProps = {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

export const CreateMessageTemplateModal: FC<CreateMessageTemplateModalProps> = props => {
  const { dialogProps, onCloseModal } = props
  const { t } = useTranslation(['common'])

  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>
            {t('common:library_items.message_template.forms.create_template_title')}
          </DialogTitle>
          <CreateMessageTemplateForm onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
