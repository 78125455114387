import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusCircleIcon } from 'lucide-react'
import { Title } from '@mantine/core'
import { PublicOfferTable } from './PublicOfferTable/PublicOfferTable'
import { Button } from '@/shared/ui/Button/Button'
import { OfferListItem } from '@/shared/api/topli/TopliApi'
import { CreatePublicOfferModal } from './Modals/CreatePublicOfferModal'
import { ReadPublicOfferModal } from './Modals/ReadPublicOfferModal'
import { DeletePublicOfferAlert } from '@/features/PublicOffers/DeletePublicOfferAlert/ui/DeletePublicOfferAlert'

export const PublicOfferView = () => {
  const { t } = useTranslation(['common'])
  const [currentTable, setCurrentTable] = useState<OfferListItem | null>(null)
  const [isOpenCreateModal, setOpenCreateModal] = useState(false)
  const [isOpenReadModal, setOpenReadModal] = useState(false)
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDialog = () => {
    setCurrentTable(null)
    setOpenCreateModal(false)
  }

  const handleOpenReadModal = (selectedPublicOffer: OfferListItem) => {
    setCurrentTable(selectedPublicOffer)
    setOpenReadModal(true)
  }

  const handleOpenDeleteModal = (selectedPublicOffer: OfferListItem) => {
    setCurrentTable(selectedPublicOffer)
    setOpenDeleteModal(true)
  }

  return (
    <div className='flex flex-col mb-5 md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <Title className='pb-5' order={4}>
        {t('common:library_items.public_offer.pl')}
      </Title>
      <PublicOfferTable
        setOpenReadModal={handleOpenReadModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={() => setOpenCreateModal(true)}
            leftIcon={<PlusCircleIcon size={16} />}
          >
            {t('common:library_items.public_offer.add_offer')}
          </Button>
        }
      />
      <CreatePublicOfferModal
        dialogProps={{ open: isOpenCreateModal, onOpenChange: setOpenCreateModal }}
        onCloseModal={handleCloseDialog}
      />
      {currentTable && (
        <ReadPublicOfferModal
          dialogProps={{ open: isOpenReadModal, onOpenChange: setOpenReadModal }}
          currentItemId={currentTable?.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable && isOpenDeleteModal && (
        <DeletePublicOfferAlert
          open={isOpenDeleteModal}
          onOpenChange={setOpenDeleteModal}
          selectedItemId={currentTable?.id}
          onCloseModal={handleCloseDialog}
        />
      )}
    </div>
  )
}
