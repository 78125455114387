import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useDeleteMessageTemplateApi } from '../api/delete-message-template-api'

export const useDeleteMessageTemplate = () => {
  const {
    mutateAsync,
    isPending: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useDeleteMessageTemplateApi()
  const { t } = useTranslation(['common'])

  const handleDeleteMessageTemplate = async (id: number, onClose: () => void) => {
    try {
      await mutateAsync(id)
    } catch (error) {
      errorHandler(error, t('common:notification_messages.delete_error'))
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.delete_message_template_success'),
      )
    }
  }, [isSuccessDelete])

  return {
    isLoadingDelete,
    handleDeleteMessageTemplate,
  }
}
