import { type AxiosError } from 'axios'

import { notification } from '@/shared/helpers/notificationMessages'
import i18next from 'i18next'

export type ErrorWithStatusCode = {
  statusCode: number
  message: string
}

export const errorHandler = (error: unknown, customErrorMessage?: string) => {
  const notificationTitle = i18next.t('notification_titles.error')
  const somethingWentWrongMessage = i18next.t('notification_messages.something_went_wrong')

  if (error instanceof Error && 'isAxiosError' in error) {
    const axiosError = error as AxiosError<ErrorWithStatusCode>
    if (axiosError?.response?.data?.message) {
      notification.error(notificationTitle, axiosError.response.data.message)
    } else {
      notification.error(notificationTitle, axiosError.toString())
    }
  } else {
    notification.error(notificationTitle, customErrorMessage || somethingWentWrongMessage)
  }
}
