import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@mantine/core'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useDeletePublicOffer } from '../lib/use-delete-offer'

type DeletePublicOfferAlertProps = {
  selectedItemId: number
  onCloseModal: () => void
}

export const DeletePublicOfferAlert: FC<DeletePublicOfferAlertProps & AlertDialogProps> = ({
  selectedItemId,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleOfferDelete } = useDeletePublicOffer()
  const { t } = useTranslation(['common'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('common:library_items.public_offer.confirmation.are_you_absolutely_sure')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t('common:library_items.public_offer.confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('common:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => {
              handleOfferDelete(selectedItemId, onCloseModal)
            }}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('common:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
