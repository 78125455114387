import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@mantine/core'
import { Form, Formik } from 'formik'
import { Button } from '@/shared/ui/Button/Button'
import { TextAreaField } from '@/shared/ui/FormFields'
import { useCreatePublicOfferForm } from '../lib/use-create-public-offer-form'

type CreatePublicOfferFormProps = {
  onCloseModal: () => void
}

export const CreatePublicOfferForm: FC<CreatePublicOfferFormProps> = props => {
  const { onCloseModal } = props
  const { initialValues, isLoadingCreatePublicOffer, submitForm } =
    useCreatePublicOfferForm(onCloseModal)
  const { t } = useTranslation(['common'])

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} enableReinitialize>
      <Form>
        <div className='flex flex-row items-center space-x-4'>
          <TextAreaField
            name='content'
            label='Markdown'
            className='flex flex-col w-full mb-4'
            placeholder='#Markdown'
            required
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base'>
            {isLoadingCreatePublicOffer ? (
              <Loader size='xs' color='white' />
            ) : (
              t('common:library_items.public_offer.create_offer')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
