import { FC } from 'react'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useDeleteMessageTemplate } from '../lib/use-delete-message-template'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'

type DeleteMessageTemplateAlertProps = {
  selectMessageTemplateId: number
  onCloseModal: () => void
}

export const DeleteMessageTemplateAlert: FC<DeleteMessageTemplateAlertProps & AlertDialogProps> = ({
  selectMessageTemplateId,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleDeleteMessageTemplate } = useDeleteMessageTemplate()
  const { t } = useTranslation(['common'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('common:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('common:library_items.message_template.confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('common:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeleteMessageTemplate(selectMessageTemplateId, onCloseModal)}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('common:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
