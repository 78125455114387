import { routeWrap } from '@/shared/api/topli/api-utils'
import {
  Address,
  AddressIn,
  AddressList,
  NewAddressParams,
  // AddressList,
  AddressSuggestions,
  Api,
  BodyPhotoUploadPhotoPost,
  BodyToken,
  Building,
  BuildingIn,
  BuildingList,
  // BuildingList,
  NewBuildingParams,
  Company,
  CompanyIn,
  CompanyList,
  // CompanyList,
  NewCompanyParams,
  Contact,
  ContactIn,
  ContactList,
  // ContactList,
  NewContactParams,
  ListArgs,
  PhotoUpdPhotoItemIdPostParams,
  PhotoUploadPhotoPostParams,
  Prices,
  PricesIn,
  PricesList,
  NewPricesParams,
  // TortoiseContribPydanticCreatorModelsPhotoLeaf,
  TortoiseContribPydanticCreatorModelsPhotoLeaf,
  GetAddrOptsGapiAddrOptsGetParams,
  Residential,
  ResidentialAmenity,
  ResidentialAmenityIn,
  ResidentialAmenityList,
  NewResidentialAmenityParams,
  // ResidentialAmenityList,
  ResidentialIn,
  ResidentialList,
  // ResidentialList,
  NewResidentialParams,
  Token,
  Unit,
  UnitAmenity,
  UnitAmenityIn,
  UnitAmenityList,
  // UnitAmenityList,
  NewUnitAmenityParams,
  UnitIn,
  UnitList,
  // UnitList,
  NewUnitParams,
  User,
  UserList,
  UserIn,
  // UserList,
  NewUserParams,
  UserReg,
  PhotosSortPhotoSortPostPayload,
  ResidentialPresentationList,
  UnitPresentationList,
  BodyAddAmenityPhotoPhotoAmenityOidPost,
  UpdateUserBody,
  TortoiseContribPydanticCreatorAuthUserLeaf,
  ChangePwdBody,
  TranslationBody,
  Order,
  OrderList,
  NewOrderParams,
  OrderIn,
  PricesBody,
  CollectionList,
  UserPhotoType,
  BodyUpdateUserPhotosUserPhotoUploadTypeUidPost,
  // Offer
  OfferList,
  NewOfferParams,
  OfferIn,
  Offer,
  //Fee
  FeeResponse,
  ErrorResponse,
  UpdateFeeResponse,
  // Template
  TemplateList,
  TemplateIn,
  NewTemplateParams,
  Template,
} from './generated/Api'
import { HttpClient, RequestParams } from '@/shared/api/utils/HttpClient'
import { HttpHandler, HttpRequest, HttpResponse } from '@shared/api/utils/http/handler/types.ts'
import { httpHandler } from '@shared/api/utils/http/handler/httpHandler.ts'
import { HttpError } from '@shared/api/utils/http/handler/HttpError.ts'
import { requestResponseToString } from '@shared/api/utils/http/handler/requestResponseToString.ts'
import { logOutUser } from '@/entities/User'
export * from './generated/Api'

export type TopliApiArgs = {
  baseUrl: string
}

// TODO: wait for update Api and remove this:
export type Photo = TortoiseContribPydanticCreatorModelsPhotoLeaf

export interface ITopliApi {
  auth: {
    readonly isSignedIn: boolean
    signIn: (data: BodyToken) => Promise<Token>
    signOut: () => Promise<void>
    signUp: (data: UserReg, params?: RequestParams) => Promise<Token>
    changePassword: (uid: number, data: ChangePwdBody, params?: RequestParams) => Promise<string>
  }
  user: {
    get: (id: number) => Promise<User>
    create: (query: NewUserParams, data: UserIn) => Promise<User>
    update: (
      uid: number,
      data: UpdateUserBody,
      params?: RequestParams,
    ) => Promise<TortoiseContribPydanticCreatorAuthUserLeaf>
    updateActiveUser: (uid: number, data: UpdateUserBody) => Promise<Token>
    list: (query: ListArgs) => Promise<UserList>
    upload_photo: (
      uid: number,
      type: UserPhotoType,
      data: BodyUpdateUserPhotosUserPhotoUploadTypeUidPost,
    ) => Promise<TortoiseContribPydanticCreatorAuthUserLeaf>
    delete: (id: number) => Promise<boolean>
  }
  unitAmenity: {
    get: (id: number) => Promise<UnitAmenity>
    create: (query: NewUnitAmenityParams, data: UnitAmenityIn) => Promise<UnitAmenity>
    update: (id: number, data: UnitAmenityIn) => Promise<UnitAmenity>
    list: (query: ListArgs) => Promise<UnitAmenityList>
    delete: (id: number) => Promise<boolean>
  }
  company: {
    get: (id: number) => Promise<Company>
    create: (query: NewCompanyParams, data: CompanyIn) => Promise<Company>
    update: (id: number, data: CompanyIn) => Promise<Company>
    list: (query: ListArgs) => Promise<CompanyList>
    delete: (id: number) => Promise<boolean>
  }
  prices: {
    get: (id: number) => Promise<Prices>
    create: (query: NewPricesParams, data: PricesIn) => Promise<Prices>
    update: (data: PricesBody) => Promise<object>
    list: (query: ListArgs) => Promise<PricesList>
    delete: (id: number) => Promise<boolean>
    addPrices: (prices: PricesBody) => Promise<unknown>
  }
  contact: {
    get: (id: number) => Promise<Contact>
    create: (query: NewContactParams, data: ContactIn) => Promise<Contact>
    update: (id: number, data: ContactIn) => Promise<Contact>
    list: (query: ListArgs) => Promise<ContactList>
    delete: (id: number) => Promise<boolean>
  }
  client: {
    get: (id: number) => Promise<Order>
    create: (query: NewOrderParams, data: OrderIn) => Promise<Order>
    update: (id: number, data: OrderIn) => Promise<Order>
    list: (query: ListArgs) => Promise<OrderList>
    delete: (id: number) => Promise<object>
  }
  residential: {
    get: (id: number) => Promise<Residential>
    create: (query: NewResidentialParams, data: ResidentialIn) => Promise<Residential>
    update: (id: number, data: ResidentialIn) => Promise<Residential>
    list: (query: ListArgs) => Promise<ResidentialList>
    delete: (id: number) => Promise<boolean>
  }
  building: {
    get: (id: number) => Promise<Building>
    create: (query: NewBuildingParams, data: BuildingIn) => Promise<Building>
    update: (id: number, data: BuildingIn) => Promise<Building>
    list: (query: ListArgs) => Promise<BuildingList>
    delete: (id: number) => Promise<boolean>
  }
  unit: {
    get: (id: number) => Promise<Unit>
    create: (query: NewUnitParams, data: UnitIn) => Promise<Unit>
    update: (id: number, data: UnitIn) => Promise<Unit>
    list: (query: ListArgs) => Promise<UnitList>
    delete: (id: number) => Promise<boolean>
  }
  residentialAmenity: {
    get: (id: number) => Promise<ResidentialAmenity>
    create: (
      query: NewResidentialAmenityParams,
      data: ResidentialAmenityIn,
    ) => Promise<ResidentialAmenity>
    update: (id: number, data: ResidentialAmenityIn) => Promise<ResidentialAmenity>
    list: (query: ListArgs) => Promise<ResidentialAmenityList>
    delete: (id: number) => Promise<boolean>
  }
  address: {
    get: (id: number) => Promise<Address>
    create: (query: NewAddressParams, data: AddressIn) => Promise<Address>
    update: (id: number, data: AddressIn) => Promise<Address>
    list: (query: ListArgs) => Promise<AddressList>
    delete: (id: number) => Promise<boolean>
  }
  photo: {
    get: (id: number) => Promise<Photo>
    create: (query: PhotoUploadPhotoPostParams, data: BodyPhotoUploadPhotoPost) => Promise<Photo>
    update: (query: PhotoUpdPhotoItemIdPostParams) => Promise<Photo>
    getBytes: (itemId: number) => Promise<any>
    sort: (data: PhotosSortPhotoSortPostPayload) => Promise<boolean>
    // list: (
    //   query: ObjectsPhotosPhotoGetParams,
    //   params?: RequestParams,
    // ) => Promise<Record<string, PydanticMainModelsPhotoLeaf[]>>
    delete: (id: number) => Promise<boolean>
  }
  amenityPhoto: {
    create: (oid: number, data: BodyAddAmenityPhotoPhotoAmenityOidPost) => Promise<any>
  }

  googleApi: {
    getAddr: (gid: string) => Promise<any>
    getAddrOpts: (query: GetAddrOptsGapiAddrOptsGetParams) => Promise<AddressSuggestions>
  }

  unitPresentation: {
    getUserUnitPresentations: (
      data: ListArgs,
      params?: RequestParams | undefined,
    ) => Promise<UnitPresentationList>
  }
  translations: {
    create: (data: TranslationBody, params: RequestParams) => Promise<string>
    update: (data: TranslationBody, params: RequestParams) => Promise<string>
  }
  collection: {
    list: (query: ListArgs) => Promise<CollectionList>
  }
  offer: {
    list: (data: ListArgs, params?: RequestParams) => Promise<OfferList>
    get: (itemId: number, params?: RequestParams) => Promise<Offer>
    create: (query: NewOfferParams, data: OfferIn, params?: RequestParams) => Promise<Offer>
    delete: (itemId: number, params?: RequestParams) => Promise<object>
  }
  fee: {
    get: (params?: RequestParams | undefined) => Promise<FeeResponse | ErrorResponse> 
    update: (newFee: number, params?: RequestParams | undefined) => Promise<ErrorResponse | UpdateFeeResponse>
  }
  template: {
    list: (data: ListArgs, params?: RequestParams) => Promise<TemplateList>
    create: (query: NewTemplateParams, data: TemplateIn, params?: RequestParams) => Promise<Template>
    get: (itemId: number, params?: RequestParams) => Promise<Template>
    update: (itemId: number, data: TemplateIn, params?: RequestParams) => Promise<Template>
    delete: (itemId: number, params?: RequestParams) => Promise<object>
  }
}

function getFullUrl({
  baseUrl,
  request,
}: {
  baseUrl?: null | string
  request: Pick<HttpRequest<any>, 'url' | 'query'>
}) {
  const url = request.url
  const fullUrl = baseUrl ? new URL(url, baseUrl) : new URL(url)

  const query = request.query
  if (query) {
    Object.keys(query).forEach(key => {
      const value = query[key]?.toString()
      if (value != null) {
        fullUrl.searchParams.set(key, value)
      }
    })
  }

  return fullUrl
}

function getHttpLog({
  baseUrl,
  request,
  response,
  includeData,
}: {
  baseUrl?: null | string
  request: HttpRequest<any>
  response?: null | HttpResponse<any, any>
  includeData?: null | boolean
}) {
  const fullUrl = getFullUrl({
    baseUrl,
    request,
  })

  let log = ''
  if (response) {
    log += `${(response.timeEnd - response.timeStart) / 1000}\t${fullUrl.href}`
  } else {
    log += `${fullUrl.href}`
  }

  if (includeData) {
    log += requestResponseToString(request, response)
  }

  return log
}

/**
 * Docs: https://api.topli.io/docs
 */
export class TopliApi implements ITopliApi {
  // region constructor
  private readonly _api: Api
  constructor({ baseUrl }: TopliApiArgs) {
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/'
    }

    const httpHandlerTopli: HttpHandler = async request => {
      let requestUrl = request.url
      if (requestUrl.startsWith('/')) {
        requestUrl = requestUrl.substring(1)
      }

      const url = baseUrl ? new URL(requestUrl, baseUrl) : new URL(requestUrl)

      const isSignIn = request.url.includes('/token')

      if (!isSignIn && this._token?.access_token) {
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${this._token?.access_token}`,
        }
      }

      try {
        const response = await httpHandler({
          ...request,
          url: url.href,
        })
        const log = getHttpLog({
          baseUrl,
          request,
          response,
          includeData: typeof window === 'undefined',
        })
        return response as any
      } catch (error) {
        if (error instanceof HttpError) {
          const log = getHttpLog({
            baseUrl,
            request,
            response: error.response,
            includeData: false,
          })
          console.error(log)
        }
        if ((error as any)?.response?.status === 401 && window.location.pathname !== '/login') {
          logOutUser()
        }
        throw error
      }
    }

    const httpClient = new HttpClient({
      httpHandler: httpHandlerTopli,
    })

    this._api = new Api(httpClient)

    const tokenStr = typeof window !== 'undefined' ? localStorage.getItem('token') : null
    this._token = tokenStr && JSON.parse(tokenStr)
  }
  // endregion

  private _token: Token | null

  private routeWrapAutoAuth: typeof routeWrap = (routeFunc, options) => {
    return routeWrap(
      () =>
        async (...args) => {
          // if (!this.auth.isSignedIn) {
          //   await this.auth.signIn({
          //     username: 'testuser',
          //     password: 'testpwd',
          //   })
          // }
          return routeFunc()(...args)
        },
      options,
    )
  }

  auth = {
    _this: this,
    get isSignedIn() {
      return !!this._this._token
    },
    get token() {
      return this._this._token
    },
    set token(value) {
      this._this._token = value
      if (typeof window !== 'undefined') {
        if (value) {
          localStorage.setItem('token', JSON.stringify(value))
        } else {
          localStorage.removeItem('token')
        }
      }
    },
    signIn: routeWrap(() => this._api.auth.token, {
      handleResult: (responseData: Token) => {
        this.auth.token = responseData
        return responseData
      },
    }),
    signOut: async () => {
      this.auth.token = null
      console.log(this.auth.token, 'this.auth.token')
    },
    signUp: routeWrap(() => this._api.auth.register),
    changePassword: routeWrap(() => this._api.auth.changePasswordChangePasswordUidPatch),
  }

  user = {
    list: this.routeWrapAutoAuth(() => this._api.user.getUserList),
    create: this.routeWrapAutoAuth(() => this._api.user.newUser),
    get: this.routeWrapAutoAuth(() => this._api.user.getUser),
    update: this.routeWrapAutoAuth(() => this._api.user.updateUserEditUserUidPatch),
    upload_photo: this.routeWrapAutoAuth(() => this._api.user.updateUserPhotosUserPhotoUploadTypeUidPost),
    updateActiveUser: this.routeWrapAutoAuth(
      () => this._api.user.updateActiveUserEditActiveUserUidPatch,
      {
        handleResult: (responseData: Token) => {
          this.auth.token = responseData
          return responseData
        },
      },
    ),
    delete: this.routeWrapAutoAuth(() => this._api.user.delUser) as any,
  }

  unitAmenity = {
    list: this.routeWrapAutoAuth(() => this._api.unitAmenity.getUnitAmenityList),
    create: this.routeWrapAutoAuth(() => this._api.unitAmenity.newUnitAmenity),
    get: this.routeWrapAutoAuth(() => this._api.unitAmenity.getUnitAmenity),
    update: this.routeWrapAutoAuth(() => this._api.unitAmenity.updUnitAmenity),
    delete: this.routeWrapAutoAuth(() => this._api.unitAmenity.delUnitAmenity) as any,
  }

  company = {
    list: this.routeWrapAutoAuth(() => this._api.company.getCompanyList),
    create: this.routeWrapAutoAuth(() => this._api.company.newCompany),
    get: this.routeWrapAutoAuth(() => this._api.company.getCompany),
    update: this.routeWrapAutoAuth(() => this._api.company.updCompany),
    delete: this.routeWrapAutoAuth(() => this._api.company.delCompany) as any,
  }

  prices = {
    list: this.routeWrapAutoAuth(() => this._api.prices.getPricesList),
    create: this.routeWrapAutoAuth(() => this._api.prices.newPrices),
    get: this.routeWrapAutoAuth(() => this._api.prices.getPrices),
    update: this.routeWrapAutoAuth(() => this._api.prices.editPricesV2PricesEditPricesPut),
    delete: this.routeWrapAutoAuth(() => this._api.prices.delPrices) as any,
    addPrices: this.routeWrapAutoAuth(() => this._api.prices.addPricesV2PricesAddPricesPut),
  }

  contact = {
    list: this.routeWrapAutoAuth(() => this._api.contact.getContactList),
    create: this.routeWrapAutoAuth(() => this._api.contact.newContact),
    get: this.routeWrapAutoAuth(() => this._api.contact.getContact),
    update: this.routeWrapAutoAuth(() => this._api.contact.updContact),
    delete: this.routeWrapAutoAuth(() => this._api.contact.delContact) as any,
  }

  client = {
    list: this.routeWrapAutoAuth(() => this._api.order.getOrderList),
    create: this.routeWrapAutoAuth(() => this._api.order.newOrder),
    get: this.routeWrapAutoAuth(() => this._api.order.getOrder),
    update: this.routeWrapAutoAuth(() => this._api.order.updOrder),
    delete: this.routeWrapAutoAuth(() => this._api.order.delOrder) as any,
  }

  residential = {
    list: this.routeWrapAutoAuth(() => this._api.residential.getResidentialList),
    create: this.routeWrapAutoAuth(() => this._api.residential.newResidential),
    get: this.routeWrapAutoAuth(() => this._api.residential.getResidential),
    update: this.routeWrapAutoAuth(() => this._api.residential.updResidential),
    delete: this.routeWrapAutoAuth(() => this._api.residential.delResidential) as any,
    // photoUpload: this.routeWrapAutoAuth(
    //   () => this._api.residential.photoUploadResidentialItemIdPhotoPost,
    // ),
  }

  building = {
    list: this.routeWrapAutoAuth(() => this._api.building.getBuildingList),
    create: this.routeWrapAutoAuth(() => this._api.building.newBuilding),
    get: this.routeWrapAutoAuth(() => this._api.building.getBuilding),
    update: this.routeWrapAutoAuth(() => this._api.building.updBuilding),
    delete: this.routeWrapAutoAuth(() => this._api.building.delBuilding) as any,
    // photoUpload: this.routeWrapAutoAuth(
    //   () => this._api.residential.photoUploadResidentialItemIdPhotoPost,
    // ),
  }

  unit = {
    list: this.routeWrapAutoAuth(() => this._api.unit.getUnitList),
    create: this.routeWrapAutoAuth(() => this._api.unit.newUnit),
    get: this.routeWrapAutoAuth(() => this._api.unit.getUnit),
    update: this.routeWrapAutoAuth(() => this._api.unit.updUnit),
    delete: this.routeWrapAutoAuth(() => this._api.unit.delUnit) as any,
  }

  residentialAmenity = {
    list: this.routeWrapAutoAuth(() => this._api.residentialAmenity.getResidentialAmenityList),
    create: this.routeWrapAutoAuth(() => this._api.residentialAmenity.newResidentialAmenity),
    get: this.routeWrapAutoAuth(() => this._api.residentialAmenity.getResidentialAmenity),
    update: this.routeWrapAutoAuth(() => this._api.residentialAmenity.updResidentialAmenity),
    delete: this.routeWrapAutoAuth(() => this._api.residentialAmenity.delResidentialAmenity) as any,
  }

  address = {
    list: this.routeWrapAutoAuth(() => this._api.address.getAddressList),
    create: this.routeWrapAutoAuth(() => this._api.address.newAddress),
    get: this.routeWrapAutoAuth(() => this._api.address.getAddress),
    update: this.routeWrapAutoAuth(() => this._api.address.updAddress),
    delete: this.routeWrapAutoAuth(() => this._api.address.delAddress) as any,
  }

  photo = {
    // list: this.routeWrapAutoAuth(() => this._api.photoModule.objectsPhotosPhotoGet),
    create: this.routeWrapAutoAuth(() => this._api.photoModule.photoUploadPhotoPost),
    get: this.routeWrapAutoAuth(() => this._api.photoModule.photoGetPhotoItemIdGet),
    update: this.routeWrapAutoAuth(() => this._api.photoModule.photoUpdPhotoItemIdPost),
    sort: this.routeWrapAutoAuth(() => this._api.photoModule.photosSortPhotoSortPost),
    getBytes: this.routeWrapAutoAuth(() => this._api.photoModule.getBytesPhotoBytesItemIdGet),
    delete: this.routeWrapAutoAuth(() => this._api.photoModule.photoDelPhotoItemIdDelete) as any,
  }

  amenityPhoto = {
    create: this.routeWrapAutoAuth(() => this._api.photoModule.addAmenityPhotoPhotoAmenityOidPost),
  }
  googleApi = {
    getAddrOpts: this.routeWrapAutoAuth(() => this._api.googleApi.getAddrOptsGapiAddrOptsGet),
    getAddr: this.routeWrapAutoAuth(() => this._api.googleApi.getAddrGapiAddrGidGet),
  }

  unitPresentation = {
    getUserUnitPresentations: this.routeWrapAutoAuth(
      () => this._api.presentation.getUnitPresPresentationGetUnitsPost,
    ),
  }

  translations = {
    create: this.routeWrapAutoAuth(() => this._api.translations.addTranslationTranslationAddPost),
    update: this.routeWrapAutoAuth(
      () => this._api.translations.editTranslationTranslationEditPatch,
    ),
  }
  collection = {
    list: this.routeWrapAutoAuth(() => this._api.collection.getCollectionList),
  }
  offer = {
    list: this.routeWrapAutoAuth(() => this._api.offer.getOfferList),
    get: this.routeWrapAutoAuth(() => this._api.offer.getOffer),
    create: this.routeWrapAutoAuth(() => this._api.offer.newOffer),
    delete: this.routeWrapAutoAuth(() => this._api.offer.delOffer),
  }
  fee = {
    get: this.routeWrapAutoAuth(
      () => this._api.platformServiceFee.getPlatformServiceFeeV2GetPlatformServiceFeeGet,
    ),
    update: this.routeWrapAutoAuth(() => this._api.platformServiceFee.updateServiceFeeV2UpdateServiceFeeNewFeePost)
  }
  template = {
    list: this.routeWrapAutoAuth(() => this._api.template.getTemplateList),
    get: this.routeWrapAutoAuth(() => this._api.template.getTemplate),
    create: this.routeWrapAutoAuth(() => this._api.template.newTemplate),
    update: this.routeWrapAutoAuth(() => this._api.template.updTemplate),
    delete: this.routeWrapAutoAuth(() => this._api.template.delTemplate),
  }
}

export const topliApi: ITopliApi = new TopliApi({
  baseUrl: import.meta.env.VITE_API_URL,
})
