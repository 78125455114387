import { Token, UserPhotoType, UserSchema, topliApi } from '@/shared/api/topli/TopliApi'
import { EditProfileFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUser, usersByPageQueryKey } from '@/entities/User'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'
import { UploadCroppedPhoto } from '@/entities/Photos/api/photos-front_crop-api'

export const editCurrentUser = async (state: EditProfileFormState) => {
  const { id, phone, email, avatar_url } = state
  let newState = state
  if (avatar_url && typeof avatar_url === 'object') {
    const result = await UploadCroppedPhoto({ id, file: avatar_url, type: UserPhotoType.Avatar })
    newState = { ...newState, avatar_url: result.avatar_url }
  }
  const body = {
    ...newState,
    username: email?.toLowerCase(),
    email,
    phone: phone ? convertPhoneStringToNumber(phone) : null,
  }
  const response = await topliApi.user.updateActiveUser(id, body)
  return response
}

export const useEditCurrentUserApi = (): UseMutationResult<Token, Error, EditProfileFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editCurrentUser(state),
    onSuccess: async res => {
      if (res) {
        const updatedUser = {
          ...res.user,
          avatar_url: res.user.avatar_url
            ? `${res.user.avatar_url}?${new Date().getTime()}`
            : res.user.avatar_url,
        }
        updateUser(updatedUser as UserSchema)
        return res.access_token
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [usersByPageQueryKey], refetchType: 'active' })
    },
  })
}
