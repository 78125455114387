import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MRT_PaginationState, type MRT_ColumnDef } from 'mantine-react-table'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { getCurrentTranslation } from '@/shared/lib/utils'
import { useMessageTemplates } from '@/entities/MessageTemplates/api/message-tempates-api'
import { TemplateListItem } from '@/shared/api/topli/TopliApi'
import { useDebounce } from '@/shared/lib/useDebounce'

export const useMessageTemplatesTable = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  })
  const [search, setSearch] = useState('')
  const { language } = useLanguageStore()
  const { t } = useTranslation(['common'])
  const debouncedSearch = useDebounce(search, 500)

  const {
    data: messageTemplates,
    isLoading: isLoadingMessageTemplates,
    isError: isErrorMessageTemplates,
    isFetching: isFetchingMessageTemplates,
  } = useMessageTemplates({
    sort: '-id',
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    search: debouncedSearch,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const fetchedMessageTemplates = messageTemplates?.data ?? []
  const totalRowCount = messageTemplates?.total ?? 0

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<TemplateListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: t('common:library_items.message_template.name'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'comment',
        header: t('common:library_items.message_template.comment'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold text-ellipsis line-clamp-2'>
            {row.original.comment}
          </div>
        ),
      },
      // {
      //   accessorKey: 'ai_translations',
      //   header: t('common:library_items.message_template.machine_translation'),
      //   Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      // },
    ],
    [language],
  )
  return {
    pagination,
    fetchedMessageTemplates,
    totalRowCount,
    columns,
    isLoadingMessageTemplates,
    isErrorMessageTemplates,
    isFetchingMessageTemplates,
    setPagination,
    handleSearchChange,
  }
}
