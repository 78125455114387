import { CommonEditUserFormState } from "@/features/Users/EditUserForm/model/types"
import { TortoiseContribPydanticCreatorAuthUserLeaf, UserPhotoType } from "@/shared/api/topli/TopliApi"
import { FileWithPath } from "@mantine/dropzone"
import axios from "axios"

interface IUploadCroppedPhoto {
  id: number, 
  file: string | FileWithPath, 
  type: UserPhotoType.Avatar | UserPhotoType.CompanyLogo,
}

export const UploadCroppedPhoto = async ({id, file, type}: IUploadCroppedPhoto) => {
  const tokenString = localStorage.getItem('token')
  const accessToken = JSON.parse(tokenString as string).access_token

  const formData = new FormData()
  formData.append('file', file)

  const result = await axios.post<TortoiseContribPydanticCreatorAuthUserLeaf, TortoiseContribPydanticCreatorAuthUserLeaf>(
    `${import.meta.env.VITE_API_URL}User/photo_upload/${type}/${id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  return result
}