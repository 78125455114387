import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { ErrorResponse, FeeResponse, topliApi, UpdateFeeResponse } from "@/shared/api/topli/TopliApi"

export const getServiceFee = async () => {
  const response = await topliApi.fee.get()
  return response
}

export const UpdateServiceFee = async (state: FeeResponse):Promise<UpdateFeeResponse | ErrorResponse> => {
  const {fee} = state
  const response = await topliApi.fee.update(fee)
  return response
}

export const useUpdateServiceFeeApi = (): UseMutationResult<
  UpdateFeeResponse | ErrorResponse,
  Error,
  FeeResponse
  > => {
  return useMutation({
    mutationFn: state => UpdateServiceFee(state),
  })
}