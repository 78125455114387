import { ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateUnitAmenityFormState, CreateUnitAmenityResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { unitAmenityListByPageQueryKey } from '@/entities/UnitAmenity/api/unit-amenity-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { addTranslation } from '@/entities/ContentTranslation'
import { hasAiTranslation } from '@/shared/lib/utils'

export const CreateUnitAmenity = async (
  state: CreateUnitAmenityFormState,
): Promise<CreateUnitAmenityResponse> => {
  const { translations, ...restState } = state
  const response = await topliApi.unitAmenity.create(
    { item_id: null },
    { name: translations.tr.name, ...restState},
  )
  return response
}

export const useCreateUnitAmenityApi = (): UseMutationResult<
  CreateUnitAmenityResponse,
  Error,
  CreateUnitAmenityFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => CreateUnitAmenity(state),
    onSuccess: async (res, state) => {
      try {
        await addTranslation(
          {
            translations: state.translations,
            modelType: ModelTypes.UnitAmenity,
          },
          res.id,
        )
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: async () => {
      try {
        queryClient.invalidateQueries({
          queryKey: [unitAmenityListByPageQueryKey],
          refetchType: 'active',
        })
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
