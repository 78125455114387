import { CloseButton, Image, Overlay, Progress, rem } from '@mantine/core'
import { Carousel, Embla } from '@mantine/carousel'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { cn } from '@/shared/lib/utils'
import { PhotoOwnerCaption } from '../model/types'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'

type PhotosCarouselProps = {
  photos: Record<string, number>[]
  isOpened: boolean
  obj_id: number
  owner: PhotoOwnerCaption
  setIsOpened: (value: boolean) => void
  onCloseModal: () => void
}

const photosModalClassName =
  'flex fixed justify-center top-0 left-0 right-0 bottom-0 flex-col w-full items-center z-[9000] transition-all'

export const PhotosCarousel: FC<PhotosCarouselProps> = props => {
  const TRANSITION_DURATION = 200
  const { photos, owner, isOpened, obj_id, onCloseModal } = props
  const [scrollProgress, setScrollProgress] = useState(0)
  const [embla, setEmbla] = useState<Embla | null>(null)
  const { tagOptions } = useTranslatedEnums()

  const mergedClassNames = cn(photosModalClassName, isOpened ? 'visible' : 'invisible')

  const handleScroll = useCallback(() => {
    if (!embla) return
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()))
    setScrollProgress(progress * 100)
  }, [embla, setScrollProgress])

  useEffect(() => {
    if (embla) {
      embla.on('scroll', handleScroll)
      handleScroll()
    }
  }, [embla])

  // This is done to bypass photo caching
  const now = useMemo(() => {
    return new Date().getTime()
  }, [])

  return (
    <div className={mergedClassNames}>
      <CloseButton
        className='absolute right-[3rem] top-[1rem] z-[1200] text-white'
        size='xl'
        variant='light'
        onClick={onCloseModal}
      />
      <Carousel
        slideSize='100%'
        slideGap='md'
        mx='lg'
        height='100%'
        getEmblaApi={setEmbla}
        initialSlide={0}
        loop={false}
        classNames={{
          root: 'z-[1100]',
          viewport: 'h-[80vh] w-[80vw]',
          slide: 'flex flex-col items-center',
          indicators: 'z-[1100]',
          indicator: 'bg-white',
          control:
            'h-[4rem] w-[4rem] text-white hover:bg-white hover:text-black rounded-full border-white',
        }}
      >
        {photos
          ?.sort((a, b) => a.sort - b.sort)
          .map(photo => (
            <Carousel.Slide key={photo.id}>
              <div className='flex flex-col relative'>
                <Image
                  width='50vw'
                  height='77.5vh'
                  src={`https://storage.googleapis.com/topli-public/${owner}/${obj_id}/${photo.id}.webp?v=${now}`}
                  classNames={{
                    root: 'flex items-center justify-center object-cover',
                  }}
                />
                <div className='flex absolute bottom-[2rem] left-[2rem] flex-row text-sm font-semibold text-left px-3 py-2 rounded-lg bg-white/80 text-primary'>
                  {tagOptions.find(tag => tag.value === photo.tag)?.label}
                </div>
              </div>
            </Carousel.Slide>
          ))}
      </Carousel>
      <Progress
        value={scrollProgress}
        styles={{ bar: { transitionDuration: '0ms' }, root: { maxWidth: rem(320) } }}
        classNames={{
          root: 'flex absolute bottom-[3.25rem] z-[9999] w-full bg-white/10',
          bar: 'bg-white',
        }}
        size='sm'
        mt='xl'
        mx='auto'
      />
      <Overlay />
    </div>
  )
}
