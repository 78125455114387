import { User, UserPhotoType, topliApi } from '@/shared/api/topli/TopliApi'
import { CommonCreateUserFormState, CreateUserFormBody, CreateUserFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { usersByPageQueryKey } from '@/entities/User'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'
import { UploadCroppedPhoto } from '@/entities/Photos/api/photos-front_crop-api'
import { CommonEditUserFormState } from '../../EditUserForm/model/types'

export const createUser = async (state: CreateUserFormState) => {
  const { phone, fullname, email, ...restState } = state
  const body: CreateUserFormBody = {
    ...restState,
    fullname: fullname,
    username: email.toLocaleLowerCase(),
    email,
    phone: convertPhoneStringToNumber(phone),
  }
  const response = await topliApi.user.create({ item_id: null }, body)
  return response
}

const UploadPhotoAndCreate = async (state: CommonCreateUserFormState) => {
  const body: CreateUserFormState = {
    ...state,
    avatar_url: null,
    company_logo_url: null,
  }
  const response = await createUser(body)
  const { id } = response
  const { avatar_url, company_logo_url } = state
  let newState = { ...state, id } as CommonEditUserFormState
  if (avatar_url && typeof avatar_url === 'object') {
    const result = await UploadCroppedPhoto({ id, file: avatar_url, type: UserPhotoType.Avatar })
    newState = { ...newState, avatar_url: result.avatar_url }
  }
  if (company_logo_url && typeof company_logo_url === 'object') {
    const result = await UploadCroppedPhoto({
      id,
      file: company_logo_url,
      type: UserPhotoType.CompanyLogo,
    })
    newState = { ...newState, company_logo_url: result.company_logo_url }
  }
  return response
}

export const useCreateUserApi = (): UseMutationResult<User, Error, CreateUserFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => UploadPhotoAndCreate(state),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [usersByPageQueryKey], refetchType: 'active' })
    },
  })
}
