import {
  TortoiseContribPydanticCreatorAuthUserLeaf,
  UserPhotoType,
  topliApi,
} from '@/shared/api/topli/TopliApi'
import { CommonEditUserFormState, EditUserFormBody, EditUserFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { usersByPageQueryKey, selectUserByIdQueryKey } from '@/entities/User'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'
import { UploadCroppedPhoto } from '@/entities/Photos/api/photos-front_crop-api'

export const editUser = async (state: EditUserFormState) => {
  const { id, phone, email, ...restState } = state

  const body: EditUserFormBody = {
    ...restState,
    username: email.toLocaleLowerCase(),
    email,
    phone: phone ? convertPhoneStringToNumber(phone) : null,
  }
  const response = await topliApi.user.update(id, body)
  return response
}

const editAllInfo = async (state: CommonEditUserFormState) => {
  const { id, avatar_url, company_logo_url } = state
  let newState = state
  if (avatar_url && typeof avatar_url === 'object') {
    const result = await UploadCroppedPhoto({ id, file: avatar_url, type: UserPhotoType.Avatar })
    newState = { ...newState, avatar_url: result.avatar_url }
  }
  if (company_logo_url && typeof company_logo_url === 'object') {
    const result = await UploadCroppedPhoto({
      id,
      file: company_logo_url,
      type: UserPhotoType.CompanyLogo,
    })
    newState = { ...newState, company_logo_url: result.company_logo_url }
  }
  const response = await editUser(newState as EditUserFormState)
  return response
}

export const useEditUserApi = (): UseMutationResult<
  TortoiseContribPydanticCreatorAuthUserLeaf,
  Error,
  EditUserFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editAllInfo(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [usersByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [selectUserByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
