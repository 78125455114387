import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikConfig } from 'formik'
import { useCreatePublicOfferApi } from '../api/create-public-offer-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { notification } from '@/shared/helpers/notificationMessages'
import { OfferIn } from '@/shared/api/topli/TopliApi'

export const useCreatePublicOfferForm = (onCloseModal: () => void) => {
  const [initialValues] = useState<OfferIn>({ name: '', content: '' })
  const { t } = useTranslation(['common'])
  const { mutateAsync, isPending: isLoadingCreatePublicOffer } = useCreatePublicOfferApi()

  const submitForm: FormikConfig<OfferIn>['onSubmit'] = async values => {
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_offer_in_progress'),
    )

    try {
      await mutateAsync(values)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_public_offer_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_public_offer_error'))
    }
  }

  return {
    initialValues,
    isLoadingCreatePublicOffer,
    submitForm,
  }
}
