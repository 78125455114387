import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikConfig } from 'formik'
import { createMessageTemplateFormDefault } from './consts'
import { notification } from '@/shared/helpers/notificationMessages'
import { useCreateMessageTemplateApi } from '../api/use-create_message-template-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { TemplateIn } from '@/shared/api/topli/TopliApi'

export const useCreateMessageTemplateForm = (
  onCloseModal: () => void,
) => {
  const [initialValues] = useState<TemplateIn>(
    () => createMessageTemplateFormDefault,
  )
  const { t } = useTranslation(['common'])

  const { mutateAsync, isPending: isLoadingCreateMessageTemplate } =
    useCreateMessageTemplateApi()

  const submitForm: FormikConfig<TemplateIn>['onSubmit'] = async values => {
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_template_in_progress'),
    )
    try {
      await mutateAsync(values)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_template_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_template_error'))
    }
  }

  return {
    initialValues,
    isLoadingCreateMessageTemplate,
    submitForm,
  }
}
