import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UsePhotosOrderStateProps = {
  queryKey: string
}

export const usePhotosOrderState = ({ queryKey }: UsePhotosOrderStateProps) => {
  const [isPhotosOrderChanged, setIsPhotosOrderChanged] = useState(false);
  const queryClient = useQueryClient()

  const handleCheckPhotosOrderChanged = (isChanged: boolean) => {
    setIsPhotosOrderChanged(isChanged)
  }

  const handleInvalidateIfChanged = () => {
    if (isPhotosOrderChanged) {
      queryClient.invalidateQueries({
        queryKey: [queryKey],
        refetchType: 'active',
      })
    }
    setIsPhotosOrderChanged(false)
  }

  return {
    isPhotosOrderChanged,
    handleCheckPhotosOrderChanged,
    handleInvalidateIfChanged,
  }
}
