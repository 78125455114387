import { Field, FieldProps, Form, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@mantine/core'
import { useCreateUserForm } from '../lib/use-create-user-form'
import { createUserFormScheme } from '../lib/consts'
import {
  CheckBoxField,
  PasswordField,
  PhoneField,
  SelectField,
  TextField,
} from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { generateRandomPassword } from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import ImageCropProvider from '@/app/providers/ImageCropProvider'
import PhotoUploder from '@/features/Photos/UploadAndFrontCrop/PhotoUploader'

type CreateUserFormProps = {
  onCloseModal: () => void
}

export const CreateUserForm: FC<CreateUserFormProps> = props => {
  const { onCloseModal } = props
  const { userRoleOptions, userStatusOptions } = useTranslatedEnums()
  const { initialValues, isLoadingCreateUser, submitForm } = useCreateUserForm(onCloseModal)
  const { t } = useTranslation(['userManagement', 'common'])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={createUserFormScheme(t)}
    >
      <Form className='pt-4'>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='is_manager'
            className='w-1/2'
            label={t('userManagement:manager')}
            disabled={isLoadingCreateUser}
          />
          <CheckBoxField
            name='is_content_manager'
            className='w-1/2'
            label={t('userManagement:content_manager')}
            disabled={isLoadingCreateUser}
          />
        </div>
        <TextField
          name='fullname'
          label={t('userManagement:username')}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingCreateUser}
        />
        <Field name='avatar_url'>
          {({ form: { values, setFieldValue } }) => (
            <ImageCropProvider>
              <PhotoUploder
                label={t('userManagement:avatar')}
                initialValue={values.avatar_url}
                name={'avatar_url'}
                setField={setFieldValue}
              />
            </ImageCropProvider>
          )}
        </Field>
        <TextField
          name='email'
          type='email'
          label={t('userManagement:email')}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingCreateUser}
        />
        <TextField
          name='company_name'
          type='company_name'
          label={t('userManagement:company_name')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateUser}
        />
        <Field name='company_logo_url'>
          {({ form: { values, setFieldValue } }) => (
            <ImageCropProvider>
              <PhotoUploder
                label={t('userManagement:company_logo')}
                initialValue={values.company_logo_url}
                name={'company_logo_url'}
                setField={setFieldValue}
              />
            </ImageCropProvider>
          )}
        </Field>
        <PasswordField
          name='password'
          type='password'
          label={t('userManagement:password')}
          className='flex flex-col w-full'
          required
          disabled={isLoadingCreateUser}
        />
        <div className='flex flex-row w-full mb-4'>
          <Field>
            {({ form: { setFieldValue } }: FieldProps) => (
              <button
                type='button'
                className='flex flex-row bg-transparent border-none text-sm text-blue'
                onClick={() => setFieldValue('password', generateRandomPassword(14))}
              >
                {t('userManagement:generate_new_password')}
              </button>
            )}
          </Field>
        </div>
        <PhoneField
          name='phone'
          label={t('userManagement:phone')}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingCreateUser}
        />
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('common:library_items.user_status.sing')}
            options={userStatusOptions}
            disabled={isLoadingCreateUser}
            className='flex flex-col w-full mb-4'
          />
          <SelectField
            name='role'
            label={t('common:library_items.user_role.sing')}
            options={userRoleOptions}
            disabled={isLoadingCreateUser}
            className='flex flex-col w-full mb-4'
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateUser}>
            {isLoadingCreateUser ? (
              <Loader size='xs' color='white' />
            ) : (
              t('userManagement:buttons.add_user')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
