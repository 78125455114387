import { Avatar, AvatarFallback, AvatarImage } from '@/widgets/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/shared/ui/Deropdown/DropdownMenu'
import { Link } from 'react-router-dom'
import { useUserStore, logOutUser } from '../model/store'
import { useTranslation } from 'react-i18next'

export const UserHeaderMenu = () => {
  const { user } = useUserStore()
  const { t } = useTranslation(['profile'])

  if (!user) {
    return null
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className={'flex items-center gap-3'}>
          <p className={'text-right font-semibold'}>{user?.fullname}</p>
          <Avatar>
            <AvatarImage
              src={user.avatar_url ? user.avatar_url : 'https://github.com/shadcn.png'}
            />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{t('profile:my_account')}</DropdownMenuLabel>
        <DropdownMenuSeparator />

        <Link className={'cursor-pointer'} to={'/profile'}>
          <DropdownMenuItem className={'cursor-pointer'}>{t('profile:profile')}</DropdownMenuItem>
        </Link>

        <DropdownMenuItem onClick={logOutUser} className={'cursor-pointer'}>
          {t('profile:log_out')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
