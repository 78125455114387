import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { CreatePublicOfferForm } from '@/features/PublicOffers/CreatePublicOfferForm/ui/CreatePublicOfferForm'

type CreatePublicOfferModalProps = {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

export const CreatePublicOfferModal: FC<CreatePublicOfferModalProps> = props => {
  const { dialogProps, onCloseModal } = props
  const { t } = useTranslation(['common'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh] max-w-[900px] '}>
        <DialogHeader>
          <DialogTitle>{t('common:library_items.public_offer.create_offer')}</DialogTitle>
        </DialogHeader>
        <CreatePublicOfferForm onCloseModal={onCloseModal} />
      </DialogContent>
    </Dialog>
  )
}
