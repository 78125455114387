import { useEffect } from 'react'
import { useUserById, useUserStore, logOutUser } from '@/entities/User'
import { Navigate } from 'react-router-dom'
import { Loader } from '@mantine/core'
import { FC, PropsWithChildren } from 'react'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'

export const PrivateRoute: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { user } = useUserStore()
  const { data: currentUser, isLoading, isError, isRefetching } = useUserById(user?.id)

  if ((isRefetching || isLoading) && !currentUser) {
    return (
      <div className='flex fixed top-0 left-0 right-0 flex-col w-full min-h-screen bg-white items-center justify-center'>
        <Loader size='lg' color='blue' />
      </div>
    )
  }

  if (isError && !isRefetching) {
    return <Navigate to={navigationRoutes.login} replace />
  }

  return <>{children}</>
}

// import { useUserById, useUserStore, logOutUser } from '@/entities/User'
// import { Navigate } from 'react-router-dom'
// import { Loader } from '@mantine/core'
// import { type FC, type PropsWithChildren } from 'react'
// import { navigationRoutes } from '@/shared/routes/navigationRoutes'

// export const PrivateRoute: FC<PropsWithChildren<unknown>> = ({ children }) => {
//   const { user } = useUserStore()

//   const { data: currentUser, isLoading, isError } = useUserById(user?.id)

//   if (isLoading && !currentUser) {
//     return (
//       <div className='flex fixed top-0 left-0 right-0 flex-col w-full min-h-screen bg-white items-center justify-center'>
//         <Loader size='lg' color='blue' />
//       </div>
//     )
//   }

//   if (isError) {
//     logOutUser()
//     return <Navigate to={navigationRoutes.login} replace />
//   }

//   return <>{children}</>
// }
