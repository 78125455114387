import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MantineReactTable,
  MRT_ShowHideColumnsButton,
  useMantineReactTable,
} from 'mantine-react-table'
import { ActionIcon as MantineActionButton } from '@mantine/core'
import { Eye, Trash } from 'lucide-react'
import { usePublicOfferTable } from '../../lib/use-publi-offer-table'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'
import { OfferListItem } from '@/shared/api/topli/TopliApi'

interface IPublicOfferTableProps {
  addButtonSlot: ReactNode
  setOpenReadModal: (row: OfferListItem) => void
  onOpenDeleteModal: (row: OfferListItem) => void
}

export const PublicOfferTable: FC<IPublicOfferTableProps> = props => {
  const { addButtonSlot, setOpenReadModal, onOpenDeleteModal } = props
  const {
    publicOffers,
    fetchedPublicOffers,
    pagination,
    sorting,
    columns,
    isErrorPublicOffers,
    isFetchingPublicOffers,
    isLoadingPublicOffers,
    setSorting,
    setPagination,
  } = usePublicOfferTable()
  const { i18n } = useTranslation()
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedPublicOffers,
    enableStickyHeader: false,
    enableEditing: true,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    rowCount: publicOffers?.total,
    positionActionsColumn: 'first',
    localization,
    renderRowActions: ({ row }) => (
      <div className='flex flex-row space-x-1 pr-8'>
        <MantineActionButton
          onClick={() => setOpenReadModal(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Eye size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenDeleteModal(row.original)}
          className='text-red bg-red/10 hover:bg-red hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Trash size={16} />
        </MantineActionButton>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-end mb-2'>
          <div className='flex flex-row items-center space-x-2'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
            {addButtonSlot}
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorPublicOffers
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingPublicOffers,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorPublicOffers,
      showProgressBars: isFetchingPublicOffers,
    },
  })

  return <MantineReactTable table={table} />
}
