import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { usePublicOfferById } from '@/entities/Offer/api/public-offer-api'

type ReadPublicOfferModalProps = {
  dialogProps: AlertDialogProps
  currentItemId: number
  onCloseModal: () => void
}

export const ReadPublicOfferModal: FC<ReadPublicOfferModalProps> = props => {
  const { dialogProps, currentItemId } = props
  const { data: currentOffer } = usePublicOfferById(currentItemId)
  const { t } = useTranslation(['amenity'])

  return (
    <Dialog {...dialogProps}>
      <DialogContent
        className={
          'overflow-y-scroll overflow-x-hidden max-h-[95vh] max-w-[900px] whitespace-break-spaces'
        }
      >
        <DialogHeader>
          <DialogTitle>{t('common:library_items.public_offer.read_offer')}</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col w-full mb-4 border-gray/30 bg-white rounded-md text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50 min-h-[8.25rem]'>
          {currentOffer?.content}
        </div>
      </DialogContent>
    </Dialog>
  )
}
