import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditMessageTemplateForm } from '@/features/MessageTemplates/EditMessageTemplateForm'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'

type EditMessageTemplateModalProps = {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
  selectedTemplateId: number
}

export const EditMessageTemplateModal: FC<EditMessageTemplateModalProps> = props => {
  const { dialogProps, onCloseModal, selectedTemplateId } = props
  const { t } = useTranslation(['common'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>
            {t('common:library_items.message_template.forms.edit_template_title')}
          </DialogTitle>
          <EditMessageTemplateForm
            selectTemplateMessageId={selectedTemplateId}
            onCloseModal={onCloseModal}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
