import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button } from '@/shared/ui/Button/Button'
import { NumberField } from '@/shared/ui/FormFields'
import { useUpdateServiceFeeForm } from '../lib/use-service-fee-form'

export const ServiceFeeForm: FC = () => {
  const { t } = useTranslation(['common'])
  const { submitForm, initialValue, isFeeEdited, setFeeEdited } = useUpdateServiceFeeForm()

  const checkIsValueChanged = values => {
    if (JSON.parse(JSON.stringify(initialValue)) !== JSON.parse(JSON.stringify(values))) {
      setFeeEdited(true)
    } else {
      setFeeEdited(false)
    }
  }

  return (
    initialValue?.fee && (
      <Formik initialValues={initialValue} onSubmit={submitForm} enableReinitialize>
        {({ values }) => (
          <Form className='flex justify-between gap-3'>
            <NumberField
              name='fee'
              className='flex flex-col w-full mb-4 max-w-[500px]'
              onHandleChange={() => {
                checkIsValueChanged(values)
              }}
              precision={1}
              step={0.1}
              min={0}
            />
            <Button type='submit' size='md' color='base' disabled={!isFeeEdited}>
              {t('common:buttons.save_changes')}
            </Button>
          </Form>
        )}
      </Formik>
    )
  )
}
