import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { errorHandler } from '@/shared/lib/errorHandler'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { IEditMessageTemplateState } from '../model/types'
import { messageTemplateByIdQueryKey } from '@/entities/MessageTemplates/api/message-tempates-api'

export const editMessageTemplate = async (state: IEditMessageTemplateState) => {
  const { id, ...restState } = state
  const response = await topliApi.template.update(id, {
    ...restState,
  })
  return response
}

export const useEditMessageTemplateApi = (): UseMutationResult => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editMessageTemplate(state as IEditMessageTemplateState),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({
          queryKey: [messageTemplateByIdQueryKey],
          refetchType: 'active',
        })
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
