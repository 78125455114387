import { Field, Form, Formik } from 'formik'
import { useEditProfileForm } from '../lib/use-edit-profile-form'
import { CheckBoxField, PhoneField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { editProfileFormSchema } from '../lib/consts'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import ImageCropProvider from '@/app/providers/ImageCropProvider'
import PhotoUploder from '@/features/Photos/UploadAndFrontCrop/PhotoUploader'

export const EditProfileForm = () => {
  const { t } = useTranslation(['profile', 'building', 'userManagement'])
  const { initialValues, isPendingEditUser, submitForm } = useEditProfileForm()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editProfileFormSchema(t)}
    >
      <Form>
        <div className='flex flex-row items-center space-x-4 mb-6 pt-4'>
          <CheckBoxField
            name='is_manager'
            className='w-1/3'
            label={t('profile:manager')}
            disabled={isPendingEditUser}
          />
          <CheckBoxField
            name='is_content_manager'
            className='w-1/3'
            label={t('profile:content_manager')}
            disabled={isPendingEditUser}
          />
        </div>

        <Field name='avatar_url'>
          {({ form: { values, setFieldValue } }) => (
            <ImageCropProvider>
              <PhotoUploder
                label={t('profile:avatar_url')}
                initialValue={values.avatar_url}
                name={'avatar_url'}
                setField={setFieldValue}
              />
            </ImageCropProvider>
          )}
        </Field>
        <TextField
          name='fullname'
          label={t('profile:username')}
          className='flex flex-col w-full mb-4'
          disabled={isPendingEditUser}
        />
        <div className='flex flex-col md:flex-row w-full md:space-x-4'>
          <TextField
            name='email'
            type='email'
            label={t('profile:email')}
            className='flex flex-col w-full mb-4'
            disabled={isPendingEditUser}
          />
          <PhoneField
            name='phone'
            label={t('profile:phone')}
            className='flex flex-col w-full mb-4'
            disabled={isPendingEditUser}
          />
        </div>
        <Button type='submit' size='md' color='base' disabled={isPendingEditUser}>
          {isPendingEditUser ? (
            <Loader size='xs' color='white' />
          ) : (
            t('common:buttons.save_changes')
          )}
        </Button>
      </Form>
    </Formik>
  )
}
