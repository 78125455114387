import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Image, Text } from '@mantine/core'
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { Trash } from 'lucide-react'
import { useImageCropContext } from '@/app/providers/ImageCropProvider'
import { readFile } from '@/shared/helpers/cropImage'
import { CropperNow } from './CropperNow'

interface IPhotoUploder {
  label?: string
  initialValue: string
  name: string
  setField: (name: string, file: FileWithPath | null) => void
}

const PhotoUploder: React.FC<IPhotoUploder> = ({ label, initialValue, setField, name }) => {
  const [file, setFile] = useState<FileWithPath | null>(null)
  const [url, setUrl] = useState<string | null>(initialValue)
  const [isCropOpen, setCropOpen] = useState(false)
  const { t } = useTranslation(['photo'])
  const { setImage, getProcessedImage, resetStates } = useImageCropContext()

  useEffect(() => {
    typeof initialValue === 'string' && setUrl(initialValue)
  }, [initialValue])

  useEffect(() => {
    onSetImage()
  }, [file])

  const onDropImage = (files: FileWithPath[]) => {
    const file = files[0]
    setFile(file)
    setCropOpen(true)
  }

  const onSetImage = async () => {
    if (file) {
      const value = await Promise.resolve(readFile(file))
      setImage(value as string)
    }
  }

  const onDeletePhoto = () => {
    setField(name, null)
    setUrl(null)
  }

  const onRevertImage = () => {
    setFile(null)
    setCropOpen(false)
    resetStates()
  }

  const handleDone = async () => {
    const image = await getProcessedImage()
    const link = URL.createObjectURL(image as FileWithPath)
    setUrl(link)
    setField(name, image as FileWithPath)
    resetStates()
    setCropOpen(false)
  }

  return (
    <div className=' mb-4'>
      {label && (
        <div className='text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2'>
          {label}
        </div>
      )}
      {!isCropOpen ? (
        url ? (
          <div className='flex flex-col relative w-[120px] h-full'>
            <div className='flex absolute w-full items-center justify-end top-2 right-[20px] gap-1 z-10 pr-2'>
              <ActionIcon
                variant='light'
                size='sm'
                className='bg-red hover:bg-red/80 text-white'
                onClick={onDeletePhoto}
              >
                <Trash size={14} />
              </ActionIcon>
            </div>
            <div>
              <Image
                src={url}
                onLoad={() => URL.revokeObjectURL(url)}
                styles={{
                  root: {
                    aspectRatio: '1/1',
                    maxWidth: '100px',
                  },
                }}
                classNames={{
                  root: 'w-[100px] rounded-sm border border-gray-300/50 object-cover h-full rounded-md p-1',
                }}
              />
            </div>
          </div>
        ) : (
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            maxFiles={1}
            onDrop={files => {
              onDropImage(files)
            }}
            classNames={{ root: 'border-dashed border-[1px] border-gray/30 object-contain' }}
          >
            <Text className='text-center'>{t('photo:drop_images_here')}</Text>
          </Dropzone>
        )
      ) : (
        <CropperNow handleDone={handleDone} onRevert={onRevertImage} />
      )}
    </div>
  )
}

export default PhotoUploder
