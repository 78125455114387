import getCroppedImg from '@/shared/helpers/cropImage'
import { createContext, useCallback, useContext, useState } from 'react'

interface ICrop {
  x: number
  y: number
}

export interface ICroppedArea {
  x: number
  y: number
  width: number
  height: number
}

interface IImageCropContext {
  image: string | undefined
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>
  zoom: number
  setZoom: React.Dispatch<React.SetStateAction<number>>
  crop: ICrop
  setCrop: React.Dispatch<React.SetStateAction<ICrop>>
  onCropComplete: (_croppedArea: ICroppedArea, croppedAreaPixels: ICroppedArea) => void
  getProcessedImage: () => Promise<unknown>
  resetStates: () => void
}

export const ImageCropContext = createContext<IImageCropContext>({
  image: undefined,
  setImage: () => {},
  zoom: 1,
  setZoom: () => {},
  crop: { x: 0, y: 0 },
  setCrop: () => {},
  onCropComplete: (_croppedArea: ICroppedArea, croppedAreaPixels: ICroppedArea) => {},
  getProcessedImage: () => Promise.resolve(),
  resetStates: () => {},
})

const defaultImage = undefined
const defaultCrop = { x: 0, y: 0 }
const defaultZoom = 1
const defaultCroppedAreaPixels = null

const ImageCropProvider = ({ children }: { children: React.ReactNode }) => {
  const [image, setImage] = useState<string | undefined>(defaultImage)
  const [crop, setCrop] = useState(defaultCrop)
  const [zoom, setZoom] = useState<number>(defaultZoom)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(defaultCroppedAreaPixels)

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const getProcessedImage = async () => {
    if (image && croppedAreaPixels) {
      const croppedImage = await getCroppedImg({ imageSrc: image, pixelCrop: croppedAreaPixels })
      const imageFile =
        croppedImage &&
        // @ts-ignore
        new File([croppedImage.file], `img-${Date.now()}.png`, {
          type: 'image/png',
        })
      return imageFile
    }
  }

  const resetStates = () => {
    setImage(defaultImage)
    setCrop(defaultCrop)
    setZoom(defaultZoom)
    setCroppedAreaPixels(defaultCroppedAreaPixels)
  }

  return (
    <ImageCropContext.Provider
      value={{
        image,
        setImage,
        zoom,
        setZoom,
        crop,
        setCrop,
        onCropComplete,
        getProcessedImage,
        resetStates,
      }}
    >
      {children}
    </ImageCropContext.Provider>
  )
}

export const useImageCropContext = () => useContext(ImageCropContext)

export default ImageCropProvider
