import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikConfig } from 'formik'
import { getServiceFee, useUpdateServiceFeeApi } from '../api/service-fee-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { FeeResponse } from '@/shared/api/topli/TopliApi'

export const useUpdateServiceFeeForm = () => {
  const [initialValue, setInitialValue] = useState<FeeResponse>()
  const [isFeeEdited, setFeeEdited] = useState<boolean>(false)

  const { t } = useTranslation(['common'])

  const { mutateAsync } = useUpdateServiceFeeApi()

  const submitForm: FormikConfig<FeeResponse>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      setFeeEdited(false)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.service_fee_updated'),
      )
    } catch (error) {
      errorHandler(error, 'Error')
      notification.error(
        t('common:notification_titles.error'),
        t('common:notification_messages.service_fee_not_updated'),
      )
    }
  }

  useEffect(() => {
    getServiceFee()
      .then(res => {
        setInitialValue(res as FeeResponse)
      })
      .catch(() => {
        notification.error(
          t('common:notification_titles.error'),
          t('common:notification_messages.service_fee_not_loaded'),
        )
      })
  }, [])

  return {
    initialValue,
    submitForm,
    isFeeEdited,
    setFeeEdited,
  }
}
