import { useMutation, useQueryClient } from "@tanstack/react-query"
import { publicOffersListQueryKey } from "@/entities/Offer/api/public-offer-api"
import { topliApi } from "@/shared/api/topli/TopliApi"

export const deletePublicOffer = async (offerId: number) => {
  const response = await topliApi.offer.delete(offerId)
  return response
}

export const useDeletePublicOfferApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id:number) => deletePublicOffer(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [publicOffersListQueryKey],
        refetchType: 'active'
      })
    }
  })
}