import { useQuery } from "@tanstack/react-query"
import { topliApi } from "@/shared/api/topli/TopliApi"
import { getOffersParams } from '../model/types'

export const publicOffersListQueryKey = 'publicOffersList'
export const currentPublicOfferByIdQueryKey = 'currentPublicOfferById'

export const getPublicOffersList = async ({limit,
  offset,
  sort}: getOffersParams) => {
  const response = await topliApi.offer.list({limit,
    offset,
    sort})
  return response
}

export const getPublicOfferById = async (id: number) => {
  const response = await topliApi.offer.get(id)
  return response
}

export const usePublicOffers = ({
  sort,
  limit,
  offset
}: getOffersParams) =>
  useQuery({
    queryKey: [publicOffersListQueryKey, sort, offset, limit],
    queryFn: () =>
      getPublicOffersList({ sort, limit, offset }),
  })

export const usePublicOfferById = (id: number) =>
  useQuery({
    queryKey: [currentPublicOfferByIdQueryKey, id],
    queryFn: () => getPublicOfferById(id),
    enabled: !!id,
  })
  
