import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import { publicOffersListQueryKey } from "@/entities/Offer/api/public-offer-api"
import { Offer, OfferIn, topliApi } from "@/shared/api/topli/TopliApi"
import { errorHandler } from "@/shared/lib/errorHandler"

export const CreatePublicOffer = async (state: OfferIn): Promise<Offer> => {
  const response = await topliApi.offer.create({item_id: null}, {...state} )
  return response
}

export const useCreatePublicOfferApi = (): UseMutationResult<
  Offer,
  Error,
  OfferIn
>  => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => CreatePublicOffer(state),
    onSettled: async () => {
      try {
        queryClient.invalidateQueries({
          queryKey: [publicOffersListQueryKey],
          refetchType: 'active',
        })
      } catch (error) {
        errorHandler(error)
      }
    },
  })
  }