import { ICroppedArea } from '@/app/providers/ImageCropProvider'

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.src = url
  })

export const readFile = (file: File) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

interface IGetCroppedImage {
  imageSrc: string
  pixelCrop: ICroppedArea
}

const getCroppedImg = async ({ imageSrc, pixelCrop }: IGetCroppedImage) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  canvas.width = image.width
  canvas.height = image.height

  ctx.translate(image.width / 2, image.height / 2)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({ file, url: file && URL.createObjectURL(file) })
    }, 'image/jpeg')
  })
}

export default getCroppedImg
