import * as Yup from 'yup'
import { TFunction } from "i18next"

export const createMessageTemplateFormScheme = (t: TFunction) => {
  return Yup.object({
    name: Yup.string()
      .min(2, t('common:validation_messages.min', { count: 2 }))
      .max(256, t('common:validation_messages.max', { count: 256 }))
      .required(t('common:validation_messages.required')),
    content: Yup.string()
      .min(12, t('common:validation_messages.min', { count: 12 }))
      .required(t('common:validation_messages.required')),
    comment: Yup.string()
      .max(256, t('common:validation_messages.max', { count: 256 }))
  })
}

export const createMessageTemplateFormDefault = {
  name: '',
  comment: '',
  content: '',
}